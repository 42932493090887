@import 'variables.scss';
@import 'mixins.scss';
@import 'example-react-component.scss';

html, body {
  background-color: #FFF;
}

body {
  color: #333;
  font-family: sans-serif;
  font-size: .9rem;
  @include atSmall {
    padding: 20px;
    font-size: 1.1rem;
  }
  @include atMedium {
    padding: 60px;
    font-size: 1.2rem;
  }
}

a:link, a:visited {
  color: $primaryColor;
}

a:hover {
  color: $secondaryColor;
}