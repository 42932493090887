.example-react-component {
  margin: 15px 0;
  background-color: $primaryColor;
  color: #FFF;
  padding: 20px;
  border-radius: 5px;

  h1 {
    font-weight: normal;
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: .88rem;
  }
}